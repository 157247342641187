import { MusiclockerFooter } from 'Components/Footer';
import { HeaderResponsive, Link } from 'Components/Header';
import { HeaderLinks } from 'Config';
import { UserStore } from 'Stores/UserStore';
import AuthedView from 'views/AuthedHome';
import HomeView from 'views/LandingPage';

export default () => {
    const isLoggedIn: boolean = UserStore.useState(s => s.loggedIn);
    return (<>
        <HeaderResponsive links={HeaderLinks}/>
        { isLoggedIn ? <AuthedView/> : <HomeView/> }
        <MusiclockerFooter/>
    </>)
} 