import { HeaderResponsive, Link } from 'Components/Header';
import { HeaderLinks } from 'Config';
import { UserStore } from 'Stores/UserStore';
import { CreateTrackView } from './CreateTrack/CreateTrackView';
import { KeySetupView } from './CreateTrack/KeySetupView';

export default () => {
    const user: any = UserStore.useState(s => s.user);
    const isLoggedIn: boolean = UserStore.useState(s => s.loggedIn);
    return (<>
        <HeaderResponsive links={HeaderLinks}/>
        { isLoggedIn && !user.uploadSetupComplete && <KeySetupView/> }
        { isLoggedIn && user.uploadSetupComplete && <CreateTrackView/> }
    </>)
} 