import axios, { Axios } from 'axios';

const client: Axios = axios.create({
    baseURL: 'https://api.musiclocker.cc/v1/',
    timeout: 10000
});
client.interceptors.request.use((config: any) => {
    if(localStorage.getItem('authToken')) {
        config.headers = { "Authorization": `Bearer ${localStorage.getItem('authToken')}`}
    }
    return config;
})

export {
    client
};