import React, { useState } from 'react';
import { createStyles, Header, Container, Group, Button, Burger, Paper, Transition, Image } from '@mantine/core';
import { useBooleanToggle } from '@mantine/hooks';
import { LoginButton } from 'Components/LandingPage/LoginButton';
import { Permission } from 'Models/Users';
import { UserStore } from 'Stores/UserStore';
import { Link } from 'react-router-dom';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    display: 'flex',
    paddingLeft: "2em",
    flexGrow: 1,
    justifyContent: 'space-between',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  linksWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
    ':hover': {
      color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 3 : 7]
    },
    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    borderBottomColor: theme.colors[theme.primaryColor][6],
    borderBottomStyle: "inset",
    borderBottomWidth: "2px",
    textDecoration: "none",
    
  },
}));

export interface Link {
  path: string;
  label: string;
  authRequired?: boolean;
  permissions?: Permission[];
}

interface HeaderResponsiveProps {
  links: Link[];
}

function containtsAll(viewPerms: Permission[], userPerms?: Permission[]) {
  if(!viewPerms || !userPerms)
    return false;
  for(let viewPerm of viewPerms) {
    if(!userPerms.includes(viewPerm))
      return false;
  }
  return true;
}

export function HeaderResponsive({ links }: HeaderResponsiveProps) {
  const [opened, toggleOpened] = useBooleanToggle(false);
  const [active, setActive] = useState(links.length > 0 && links[0].path);
  const { classes, cx } = useStyles();
  const isLoggedIn = UserStore.useState(s => s.loggedIn);
  const loggedInUser = UserStore.useState(s => s.user);

  const shouldBeVisible = (link: Link): boolean => {
    if(link.authRequired && !isLoggedIn) {
      return false;
    }
    if(link.permissions && !(containtsAll(link.permissions, loggedInUser?.permissions))) {
      return false;
    }
    return true;
  };

  const items = links.map((link) => (
    shouldBeVisible(link) && 
    (<Button
      key={link.label}
      component={Link}
      to={link.path}
      variant={ "subtle" }
      className={cx(classes.link, { [classes.linkActive]: active === link.path })}
      onClick={() => {
        setActive(link.path);
        toggleOpened(false);
      }}
    >{link.label}</Button>)
  ));

  return (
    <Header height={HEADER_HEIGHT} mb={20} className={classes.root}>
      <Container className={classes.header}>
        <Image width={110} height={30}  withPlaceholder={true}/>
        <Group spacing={5} className={classes.links}>
          { items.length > 0 && <Group spacing={2} classNames={classes.linksWrapper}>{items}</Group> }
          
          <LoginButton/>
        </Group>

        <Burger
          opened={opened}
          onClick={() => toggleOpened()}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
              <LoginButton/>
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}