import { UserSummary } from "Models/Users";
import { Store } from "pullstate";

export interface IUserStore {
    user?: UserSummary;
    loggedIn: boolean;
}

export const UserStore = new Store<IUserStore>({
    loggedIn: false
});