import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import {useModals} from '@mantine/modals';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { login } from 'Api/auth';
import { UserStore } from 'Stores/UserStore';

export function LoginForm() {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    }

  });

  const modalManager = useModals();
  const handleSubmit = (values: typeof form.values) => {

      setErrorMessage('');
      setLoading(true);

      login(values.email, values.password).then((response) => {
        UserStore.update(s => {
            s.loggedIn = true;
            s.user = response.data.profile;
        });
        localStorage.setItem('authToken', response.data.authKey);
        modalManager.closeAll();
      }).catch((err) => {
        if(err.response.data.error) {
          setErrorMessage(err.response.data.error);
        } else {
          setErrorMessage(err.message);
        }
        setLoading(false);
      });
  };

  return (
    <Container size={420} my={40}>
      <LoadingOverlay visible={isLoading} />
      <Title
        align="center"
        sx={(theme: { fontFamily: any; }) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Don't have an account yet?
        <Anchor<'a'> href="#" size="sm"onClick={() => { modalManager.openContextModal('registerModal', {innerProps: {}}); }}>
          Create account
        </Anchor>
      </Text>

      <Paper shadow="md" mt={30} radius="md">
        { errorMessage !== '' && <Text size="sm" color="red" align='center'>{errorMessage}</Text> }
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput label="Email" placeholder="you@mantine.dev" data-autofocus required {...form.getInputProps('email')} />
          <PasswordInput label="Password" placeholder="Your password" required mt="md" {...form.getInputProps('password')} />
          <Group position="apart" mt="md">
            <Checkbox label="Remember me" />
            <Anchor<'a'> onClick={(event) => event.preventDefault()} href="#" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </form>
      </Paper>
    </Container>
  );
}