import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
  } from '@mantine/core';
import { useForm } from '@mantine/hooks';
import { useState } from 'react';
  
  interface FormProps{
    username?: string;
    email?: string;
    password: string;
  }

  RegisterForm.defaultProps = {
    username: '',
    email: '',
    password: ''
  }

  
  export function RegisterForm(props: FormProps) {

    const [passwordConf, setPasswordConf] = useState<string>('');

    const form = useForm<FormProps>({
      initialValues: props,
      validationRules: {
        email: (value) => /^\S+@\S+$/.test(value || ''),
      }
    })

    return(
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme: { fontFamily: any; }) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Welcome!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Already have an account?{' '}
          <Anchor<'a'> href="#" size="sm" onClick={(event) => event.preventDefault()}>
            Login
          </Anchor>
        </Text>
      
        <form onSubmit={form.onSubmit((values: FormProps) => console.log(values))}>
        <Paper mt={30} radius="md">
          <TextInput label="Username" placeholder="Your username" required {...form.getInputProps('username')} />
          <TextInput label="Email" placeholder="Your email" required {...form.getInputProps('email')}/>
          <PasswordInput label="Password" placeholder="Your password" required {...form.getInputProps('password')} mt="md" />
          <PasswordInput label="Confirm your password" placeholder="Confirm your password" required mt="md" value={passwordConf} onChange={(event) => setPasswordConf(event.currentTarget.value)} error={!(form.getInputProps('password').value === passwordConf)}/>
          <Group position="apart" mt="md">
            <Checkbox label="Remember me" />
            <Anchor<'a'> onClick={(event) => event.preventDefault()} href="#" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type='submit'>
            Register
          </Button>
        </Paper>
        </form>
      </Container>
    );
  }