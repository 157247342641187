import {client} from './axios';

export function login(email: string, password: string) {
    return client.post('user/auth', {
        email: email,
        password: password
    });
};

export function register(email: string, username: string, password: string) {
    return client.post('user/register', {email, username, password});
}