import { Container } from "@mantine/core";
import { Grid, Paper } from '@mantine/core';
import { DropzoneButton } from 'Components/AudioFileUploadZone';
import { HeaderResponsive } from "Components/Header";
import { useParams } from "react-router-dom";

import { HeaderLinks } from "Config";
import { CreationForm } from "Components/CreateTrack/CreationForm";


function EditTrackComponent() {
    const params: any = useParams();
    const {id} = params;
    return (
        <Paper shadow="xs" p="md">
            <Grid columns={4} gutter="sm" pt={"2em"}>
                <Grid.Col span={4}>
                    <CreationForm isCreation={false} id={id}/>
                </Grid.Col>
                <Grid.Col span={4}>
                    <DropzoneButton id={id}/>
                </Grid.Col>
            </Grid>
        </Paper>
    )
}
    
export function EditTrackView() {
    return (<>
            <HeaderResponsive links={HeaderLinks}/>
            <Container>
                <EditTrackComponent/>
            </Container>
        </>)
    }
