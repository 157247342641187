import { AppShell, useMantineTheme } from '@mantine/core';
import { GroupBuyTable } from 'Components/AuthedHome/GroupBuyTable';
import { GroupbuyStatsWidget } from 'Components/AuthedHome/GroupBuyStatsWidget';
import { DashboardNavBar } from 'Components/AuthedHome/DashboardNavBar';

export default () => {
    const theme = useMantineTheme();
    return (<>
    <AppShell
      padding="md"
      navbar={<DashboardNavBar/>}
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.dark},
      })}
    >
      <GroupbuyStatsWidget data={[{label: "Active groupbuys", stats: "3"}, {label: "Completed groupbuys", stats: "75"}]}/>
      <GroupBuyTable data={[{title: 'Unreleased Track 1', artist: 'Juice WRLD', seller: 'Username', price: 2000, id: '1'}, {title: 'Unreleased Track 2', artist: 'Lil Uzi Vert', seller: 'Username', price: 2000, id: '2', }, {title: 'Unreleased Track 3', artist: 'Playboi Carti', seller: 'Username', price: 2000, id: '3', }]}/>
    </AppShell>
    </>);
};