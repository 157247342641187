import { Text, SimpleGrid, Paper, Group, Container, Center } from '@mantine/core';

interface GroupbuyStatsWidgetProps {
  data: {
    label: string;
    stats: string;
  }[];
}


export function GroupbuyStatsWidget({ data }: GroupbuyStatsWidgetProps) {
  const stats = data.map((stat) => {
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Group>
          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {stat.stats}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });
  return (
    
    <SimpleGrid cols={4} breakpoints={[{ maxWidth: 40, cols: 1 }]}>
      {stats}
    </SimpleGrid>
  );
}