import { Button, Checkbox, Grid, LoadingOverlay, NumberInput, Paper, SegmentedControl, Text, Textarea, TextInput } from '@mantine/core';
import {useForm} from '@mantine/form';
import { createTrack, editTrack, fetchTrack } from 'Api/tracks';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ChevronRight } from 'tabler-icons-react';
import { ArtistSelectionInput } from './ArtistSelectionInput';
import { FeatureMultiSelection } from './FeatureMultiSelection';

export function CreationForm({isCreation, id} : {isCreation: boolean, id?: string}) {
    const [trackId, setTrackId] = useState('');
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(!isCreation);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            artist: '',
            name: '',
            features: [],
            price: 0,
            anonymous: false,
            description: '',
            releaseType: 'UNFINISHED'
        },
        validate: {
            artist: (value) => (value.length > 0 ? null : 'Invalid email'),
            name: (value) => (value.length > 0 ? null : 'Invalid email'),
        }
    });

    const createEditTrackSchema = (values: typeof form.values) => {
        return {
            artist: values.artist,
            name: values.name,
            price: values.price,
            type: values.releaseType,
            anonymous: values.anonymous,
            description: values.description,
            features: values.features
        };
    }

    const handleCreation = (values: typeof form.values) => {
        setBusy(true);
        createTrack(createEditTrackSchema(values)).then((response) => {
            navigate("/group-buy/edit/"+response.data.id);
        }).catch((err) => {
            if(err.response.data.error) {
              setErrorMessage(err.response.data.error);
            } else {
              setErrorMessage(err.message);
            }
            setBusy(false);
        });
    };

    const handleEdit = (values: typeof form.values) => {
        setBusy(true);
        if(!id)
            return;
        editTrack(id, createEditTrackSchema(values)).then((response) => {
            console.log("Updated", response.data);
            setBusy(false);
        }).catch((err) => {
            if(err.response.data.error) {
                setErrorMessage(err.response.data.error);
              } else {
                setErrorMessage(err.message);
              }
              setBusy(false);
        })
    };

    useEffect(() => {
        if(id && !isCreation) {
            setTrackId(id);
            fetchTrack(id).then((response) => {
                console.log(`We got`, response.data);
                const weSetting = {
                    artist: response.data.artist,
                    name: response.data.name,
                    price: response.data.price,
                    releaseType: response.data.type,
                    anonymous: response.data.anonymous,
                    description: response.data.description,
                    features: response.data.features
                };
                form.setValues(weSetting);
                console.log(`We set`, weSetting);
                setLoading(false);
            });
        }
    }, []);

    const releaseType = ['Unfinished', 'Finished'].map((type) => { return {label: type, value: type.toUpperCase() } });
    return (
        <Paper shadow="xs" p="md">
            { !isCreation && <LoadingOverlay visible={isLoading}/>}
            { errorMessage !== '' && <Text size="sm" color="red" align='center'>{errorMessage}</Text> }
            <form onSubmit={form.onSubmit(isCreation ? handleCreation : handleEdit)}>
                <Grid columns={2} gutter="sm" >
                    <Grid.Col span={2}><ArtistSelectionInput form={form} disabled={isBusy}/></Grid.Col>
                    <Grid.Col span={2}><TextInput disabled={isBusy} label="Track Name" placeholder='Enter a track name' {...form.getInputProps('name')} required/></Grid.Col>
                    <Grid.Col span={2}>
                        <NumberInput disabled={isBusy} label="Price" 
                            parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
                            formatter={(value: any) => !Number.isNaN(parseFloat(value))  ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '$ '}
                            placeholder="Price for Group Buy"
                            required {...form.getInputProps('price')}
                        />
                    </Grid.Col>
                    <Grid.Col span={2}><FeatureMultiSelection form={form} disabled={isBusy}/></Grid.Col>
                    <Grid.Col span={2}><Textarea label="Description" {...form.getInputProps('description')}></Textarea></Grid.Col>
                    <Grid.Col span={1}>
                        <Text size='sm' style={{fontWeight: '500', marginBottom: 4}}>Finish State:</Text>
                        <SegmentedControl disabled={isBusy} color="green" data={releaseType} {...form.getInputProps('releaseType')}/>
                    </Grid.Col>
                    <Grid.Col span={1} style={{ display: "flex", flexDirection: "column", justifyContent: "end", flexWrap: "wrap", alignContent: "flex-end"}}>
                        <Checkbox disabled={isBusy} label="Anonymous Listing" onChange={(e) => form.getInputProps('anonymous').onChange(e)} checked={form.getInputProps('anonymous').value} style={{marginBottom: 4}}/>
                        <Button loading={isBusy} size="sm" color="green" rightIcon={<ChevronRight size={14}/>} type="submit">{isCreation ? "Proceed to Upload" : "Save"}</Button>
                    </Grid.Col>
                </Grid>
            </form>
        </Paper>);

}