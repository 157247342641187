import { ContextModalProps } from '@mantine/modals';
import { RegisterForm } from '../RegisterForm';
import { LoginForm } from '../LoginForm';

export const LoginModal = ({ context, id, innerProps }: ContextModalProps<any>) => (
    <>
        <LoginForm/>
    </>
);

export const RegisterModal = ({ context, id, innerProps }: ContextModalProps<any>) => (
  <>
      <RegisterForm/>
  </>
);