import React, { useState } from 'react';
import { createStyles, Table, Checkbox, ScrollArea, Group, Avatar, Text, Anchor, Badge } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },
}));

interface GroupBuyTableProps {
  data: { title: string; artist: string; seller: string; price: number; id: string;}[];
}

export function GroupBuyTable({ data }: GroupBuyTableProps) {
  const { classes, cx } = useStyles();
  const [selection, setSelection] = useState(['1']);

  const rows = data.map((item) => {
    const selected = selection.includes(item.id);
    return (
      <tr key={item.id}>
        <td>
        <Group spacing="sm">
          <Anchor href="/groupbuy/<id>">
            <Text size="sm" weight={500}>
              {item.title}
            </Text>
          </Anchor>
          </Group>
        </td>
        <td>
          <Group spacing="sm">
            <Badge variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                {item.artist}
            </Badge>
          </Group>
        </td>
        <td>
        <Group spacing="sm">
          <Anchor href="#">
            <Text size="sm" weight={500}>
              {item.seller}
            </Text>
          </Anchor>
        </Group>
        </td>
        <td>
          ${item.price}
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>Title</th>
            <th>Artist</th>
            <th>Seller</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}