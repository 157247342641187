import { getCurrentProfile } from "Api/user";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, HashRouter, Routes, useParams } from "react-router-dom";
import { UserStore } from 'Stores/UserStore';
import { EditTrackView } from "views/CreateTrack/EditTrackView";
import IndexView from "views/IndexView";
import { NotFoundView } from "views/NotFound";
import TrackCreationView from "views/TrackCreationView";

const queryClient = new QueryClient();

class App extends React.Component {

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if(localStorage.getItem('authToken')) {
        getCurrentProfile().then((response) => {
          UserStore.update(s => {
            s.user = response.data;
            s.loggedIn = true;
          })
        });
    }
  }

  render() {
    return (
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <Routes>
              <Route path="/" element={<IndexView/>}/>
              <Route path="/group-buy/create" element={<TrackCreationView/>}/>
              <Route path="/group-buy/edit/:id" element={<EditTrackView/>}/>
              <Route path="/*" element={<NotFoundView/>}/>
            </Routes>
          </HashRouter>
        </QueryClientProvider>  
    );
  }

}

export default App;