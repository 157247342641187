import { Container, Text, Title, Grid } from '@mantine/core';
import { TrendingGroupBuy } from './TrendingGroupBuy';

const IndexActiveGroupbuys = () => {

  return (
    <section id="section-active-groupbuys">
            <Container>
                <Text color="white" align="center">
                    <Title order={1} style={{ marginBottom: 30 }}>Popular right now!</Title>
                </Text>
                <Grid>
                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <TrendingGroupBuy/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <TrendingGroupBuy/>
                    </Grid.Col>
                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <TrendingGroupBuy/>
                    </Grid.Col>
                </Grid>

            </Container>
        </section>
    );
};

export default IndexActiveGroupbuys;
