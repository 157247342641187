import {Button} from '@mantine/core';
import {useModals} from '@mantine/modals';
import { ProfileButton } from 'Components/ProfileButton';
import { useStoreState } from 'pullstate';
import { UserStore } from 'Stores/UserStore';

export function LoginButton() {
    const modals = useModals();
    const loggedIn = useStoreState(UserStore, s => s.loggedIn);
    return (
        loggedIn ? <ProfileButton/> : 
        <Button onClick={() => {
            modals.openContextModal('loginModal', { centered: true, closeOnEscape: false, closeOnClickOutside: false, innerProps: {}});
        }}>Login</Button>
    )
}