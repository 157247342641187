import {Autocomplete} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { searchArtists } from 'Api/tracks';
import { useState } from 'react';

export function ArtistSelectionInput({form, disabled}: {form: UseFormReturnType<any>, disabled: boolean}) {
    const [searchData, setSearchData] = useState([]);
    const search = (value: string) => {
        if(value.length === 0) {
            return setSearchData([]);
        }
        searchArtists(value).then((response) => {
            setSearchData(response.data.results.map((artist: any) => artist.name));
        });
    };
    const _onChange = (value: string) => {
        form.getInputProps('artist').onChange(value);
        search(value);
    }
    return (<Autocomplete disabled={disabled} required label="Artist" value={form.getInputProps('artist').value} onChange={_onChange} placeholder="Pick an Artist" data={searchData}/>)
}
