import React, { useRef, useState } from 'react';
import { Text, Group, Button, createStyles, MantineTheme, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneStatus, MIME_TYPES } from '@mantine/dropzone';
import { CloudUpload } from 'tabler-icons-react';
import { uploadTrack } from 'Api/tracks';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    marginBottom: 30,
  },

  dropzone: {
    borderWidth: 1,
    paddingBottom: 50,
  },

  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  control: {
    position: 'absolute',
    width: 250,
    left: 'calc(50% - 125px)',
    bottom: -20,
  },
}));

function getActiveColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][6]
    : status.rejected
    ? theme.colors.red[6]
    : theme.colorScheme === 'dark'
    ? theme.colors.dark[0]
    : theme.black;
}

export function DropzoneButton({id}: {id: string}) {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const openRef = useRef<() => void>();
  const mimeTypes = [
    'audio/mpeg', 'audio/x-aiff', 'audio/wav', 'audio/ogg',
    'audio/x-m4a', 'audio/flac'
  ];

  const [fileSelected, hasFileSelected] = useState(false);
  const [file, setFile] = useState<File>();

  const uploadFile = (event: any) => {
      if(file)
        uploadTrack(id, file);
  };

  return (
    <div className={classes.wrapper}>
      <Dropzone
        onDrop={(files: File[]) => {
            setFile(files[0]);
            hasFileSelected(true)
        }}
        accept={mimeTypes}
        className={classes.dropzone}
        radius="md"
        maxSize={75 * 1024 ** 2}
      >
        {(status) => {
            return <>
                {!fileSelected && 
                    <div style={{ pointerEvents: 'none' }}>
                        <Group position="center">
                          <CloudUpload size={50} color={getActiveColor(status, theme)} />
                        </Group>
                        <Text
                        align="center"
                        weight={700}
                        size="lg"
                        mt="xl"
                        sx={{ color: getActiveColor(status, theme) }}
                        >
                        {status.accepted
                            ? 'Drop Track here'
                            : status.rejected
                            ? 'Audio file less than 75mb'
                            : 'Upload Track'}
                        </Text>
                        <Text align="center" size="sm" mt="xs" color="dimmed">
                        Drag&apos;n&apos;drop your track file here to upload. We can accept only <i>mp3,m4a,wav,ogg,aiff,flac</i> formats and files that
                        are less than 75mb in size.
                        </Text>
                    </div>
                }
                {fileSelected && <><h1>Selected File:{file?.name}</h1></>}
            </>
            }
        }
      </Dropzone>
      {fileSelected && <Button onClick={uploadFile}>Upload File</Button>}
    </div>
  );
}