import { Container } from "@mantine/core";
import { CreationForm } from "Components/CreateTrack/CreationForm";
import React from "react";

export class CreateTrackView extends React.Component {
    
    render() {
        
        return (<>
            <Container>
                <CreationForm isCreation={true}/>
            </Container>
        </>)
    }

}