import { Container, Text, Button } from "@mantine/core";
import { MantineProvider } from "@mantine/styles";
import { ContextModalProps, ModalsProvider } from '@mantine/modals';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import { LoginModal, RegisterModal } from "Components/Modal/LoginModal";

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider theme={{  colorScheme: 'dark' }} withGlobalStyles>
      <ModalsProvider modals={{ loginModal: LoginModal, registerModal: RegisterModal }}>
        <Container fluid><App/></Container>
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);