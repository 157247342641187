import { Button, Divider, Menu, MenuItem } from '@mantine/core';
import { Permission, Role } from 'Models/Users';
import { Link } from 'react-router-dom';
import { UserStore } from 'Stores/UserStore';
import { User, Wallet, Logout, CurrencyBitcoin, Plug, Music, CaretDown } from 'tabler-icons-react';

const ICON_SIZE = 16;

function logout() {
    localStorage.removeItem('authToken');
    UserStore.update(s => {
        s.loggedIn = false;
        s.user = undefined;
    })
}

function userCanUpload(user: any) {
    return user?.permissions.includes(Permission.CanUpload) || user.role !== Role.User;
}

export function ProfileButton() {
    const user: any = UserStore.useState(s => s.user);
    return (
        <Menu control={<Button rightIcon={<CaretDown size={ICON_SIZE}/>}>{user?.displayName}</Button>}>
            <Menu.Item icon={<User size={ICON_SIZE} />}>Profile</Menu.Item>
            <Menu.Item icon={<Music size={ICON_SIZE}/>}>My Group Buys</Menu.Item>
            { userCanUpload(user) && <Menu.Item component={Link} to="/group-buy/create" icon={<Plug size={ICON_SIZE}/>}>Create Group Buy</Menu.Item>}
            <Divider/>
            <Menu.Item icon={<Wallet size={ICON_SIZE} />}>Balance: {user?.displayBalance}</Menu.Item>
            <Menu.Item icon={<CurrencyBitcoin size={ICON_SIZE}/>}>Deposit</Menu.Item>
            <Divider/>
            <Menu.Item onClick={logout} icon={<Logout size={ICON_SIZE} />}>Logout</Menu.Item>
        </Menu>
    )
}