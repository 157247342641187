import { Center, Container, Grid, Checkbox, Button, Loader, Textarea, createStyles, Text, SimpleGrid, Group, ThemeIcon } from "@mantine/core";
import { generateKeyPair, verifyKeypair, getCurrentProfile } from "Api/user";
import {readMessage, decrypt, readPrivateKey } from 'openpgp';
import React, {useState} from "react";
import { UserStore } from 'Stores/UserStore';
import { CircleCheck } from "tabler-icons-react";

type SetupStage = 'explain' | 'generate' | 'verify' | 'complete'; 


const useStyles = createStyles((theme) => ({

    textArea: {
        paddingRight: "11%",
        paddingLeft: "11%",
        [theme.fn.smallerThan('sm')]: {
            paddingRight: "2%",
            paddingLeft: "2%",
        }
    }
}));

function ExplainationComponent({ progress }: {progress: any}) {
    const [agreed, setAgreed] = useState(false);
    return (
        <Grid>
            <Grid.Col span={12}><Center><h1>Key Creation</h1></Center></Grid.Col>
            <Grid.Col><p>Before you can upload a track, you will need to setup your encryption key.
                <br/>
                An encryption key is required to keep everything you upload secured and only decryptable by you.
                <br/>
                All uploads are stored encrypted with Military-grade AES-256 bit encryption until the group buy is complete and released by you.
                <br/>
                You MUST keep this key safe and private to only yourself, without this you will not be able to to claim payment.
                </p>
                
            </Grid.Col>
            <Grid.Col><Center><Checkbox label="I understand and wish to proceed" checked={agreed} onChange={(event) => setAgreed(event.currentTarget.checked)}/></Center></Grid.Col>
            <Grid.Col><Center><Button fullWidth disabled={!agreed} onClick={() => progress('generate')}>Continue</Button></Center></Grid.Col>
    </Grid>);
};

function GenerationComponent({ privateKey, busy, progress, saveKey }: {privateKey: string, busy: boolean, progress: any, saveKey: any}) {
    const { classes, cx } = useStyles();
    return (
        <>
            
            { busy && 
                (<Grid>
                    <Grid.Col><Loader size="xl"/></Grid.Col>
                    <Grid.Col><h3>Your private key is being generated, please wait a moment.</h3></Grid.Col>
                </Grid>)
            }
            { !busy && 
            <>
                <SimpleGrid cols={1}>
                    <div><Center><h1>Your Private Key</h1></Center></div>
                    <div className={classes.textArea}>
                        <Textarea size="xs" styles={{input: {
                            fontFamily: 'monospace',
                            minWidth: '100%'
                        }}} value={privateKey} autosize disabled={true} minRows={12}/>
                    </div>
                    <div>
                        <Text align="center" size="sm">Please keep this private key safe and private to yourself.
                        <br/>
                        Never reveal this to anyone and only provide it when required to for finalizing a group buy.</Text>
                    </div>
                    <div>
                        <Group position="center" sx={{ padding: 15 }}>
                            <Button sx={{ width: "40%" }} onClick={() => saveKey() }>Download Key</Button>
                            <Button sx={{ width: "40%" }} onClick={() => progress('verify')}>Verify Key</Button>
                        </Group>
                    </div>
                </SimpleGrid>
            </>
            }
        </>
    );
}

function VerificationComponent({verifyKey, busy}: {verifyKey: any, busy: boolean}) {
    const [inputKey, setInputKey] = useState("");
    return (
        <>
            <SimpleGrid cols={1} style={{minWidth: "50%"}}>
                    <div><Center><h1>Verify Private Key</h1></Center></div>
                    <div>
                        <Textarea size="xs" styles={{input: {
                            fontFamily: 'monospace'
                        }}} value={inputKey} disabled={busy} onChange={(event) => setInputKey(event.target.value)} autosize minRows={12}/>
                    </div>
                    <div style={{minWidth: "100%"}}>
                        <Text align="center" size="sm">Please input the private key provided in the previous step.</Text>
                    </div>
                    <div>
                        <Group position="center" sx={{ padding: 15 }}>
                            <Button loading={busy} sx={{ width: "100%" }} color="green" onClick={() => verifyKey(inputKey) }>Verify Key</Button>
                        </Group>
                    </div>
            </SimpleGrid>
        </>
    );
}

function CompletionComponent({finish}: {finish: any}) {
    return (<>
        <SimpleGrid cols={1} style={{minWidth: "50%"}}>
                <div><Center><h1>Setup Complete</h1></Center></div>
                <div>
                    <Center><CircleCheck color="green" size={256}/></Center>
                </div>
                <div style={{minWidth: "100%"}}>
                    <Text align="center" size="sm">Encryption key setup has been complete and you may now upload music.</Text>
                </div>
                <div>
                    <Group position="center" sx={{ padding: 15 }}>
                        <Button sx={{ width: "100%" }} color="green" onClick={() => finish()}>Continue</Button>
                    </Group>
                </div>
        </SimpleGrid>
    </>)
}

export class KeySetupView extends React.Component {

    state = {
        stage: 'explain',
        busy: true,
        privateKey: "",
        verificationMessage: "",
        decryptedMessage: ""
    };

    constructor(props: any) {
        super(props);
        this.updateSetupState = this.updateSetupState.bind(this);
        this.saveKey = this.saveKey.bind(this);
        this.verifyKey = this.verifyKey.bind(this);
        this.finalize = this.finalize.bind(this);
    }

    updateSetupState(state: SetupStage) {
        this.setState({
            stage: state
        });
        if(state === 'generate') {
            generateKeyPair().then((response) => {
                this.setState({
                    busy: false,
                    privateKey: response.data.privateKey,
                    verificationMessage: response.data.verificationMessage
                });
            }).catch((err) => {
                console.error("Failed to call generate key pair!", err);
                console.error(err.response.data.error ? err.response.data.error : err.message);
            })
        }
    }
    
    saveKey() {
        var a = document.createElement('a');
        var blob = new Blob([this.state.privateKey], {'type': 'application/octet-stream'});
        a.href = window.URL.createObjectURL(blob);
        a.download = "musiclocker-signing.key";
        a.click();
    }

    async decryptMessage(privKey: string) {
        const privateKey = await readPrivateKey({ armoredKey: privKey });
        const encryptedMessage = await readMessage({ armoredMessage: this.state.verificationMessage });
        const { data: decrypted  } = await decrypt({
            message: encryptedMessage,
            decryptionKeys: privateKey
        });
        return decrypted;

    }

    verifyKey(keyInput: string) {
        this.setState({busy: true});
        this.decryptMessage(keyInput).then((data: any) => {
            verifyKeypair(data).then((response) => {
                this.setState({stage: 'complete'}); 
            })
        }).catch((err) => {
            //TODO: Error mangement for views!
            console.error(`Failed decrypting message`, err);
        })
    }

    finalize() {
        getCurrentProfile().then((response) => {
            UserStore.update(s => {
                s.user = response.data;
            });
        });
    }

    render() {
        return (
        <Container> 
            <Center>
                {this.state.stage === 'explain' && <ExplainationComponent progress={this.updateSetupState}/>}
                {this.state.stage === 'generate' && <GenerationComponent privateKey={this.state.privateKey} saveKey={this.saveKey} busy={this.state.busy} progress={this.updateSetupState}/>}
                {this.state.stage === 'verify' && <VerificationComponent verifyKey={this.verifyKey} busy={this.state.busy}/>}
                {this.state.stage === 'complete' && <CompletionComponent finish={this.finalize}/>}
            </Center>
        </Container>);
    }

}