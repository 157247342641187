export enum Permission {
    CanUpload = "CAN_UPLOAD"
}

export enum Role {
    User = "USER",
    Staff = "STAFF",
    Admin = "ADMIM"
}

export interface UserProfile {

    id: string;
    displayName: string;
    registeredSince: string;
    urlSlug: string;
    totalTracks: number;
    totalReleasedTracks: number;
    
}

export interface UserSummary {

    displayName: string;
    displayBalance: string;
    inEscrowBalance: string;
    role: Role;
    permissions: Permission[];
    uploadSetupComplete: boolean;

}

export interface UserCreationResponse {

    profile: UserSummary;
    authKey: string;
}

export function userCanUpload(user: UserSummary): boolean {
    return user.permissions.includes(Permission.CanUpload) || user.role !== Role.User;
}