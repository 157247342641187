import { MultiSelect } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form/lib/use-form";
import { getAllArtists } from "Api/tracks";
import { useEffect, useState } from "react";

export function FeatureMultiSelection({form, disabled}: {form: UseFormReturnType<any>, disabled: boolean}) {
    const [data, setData] = useState<string[]>([]);
    useEffect(() => {
        getAllArtists().then((response) => setData(response.data.results.map((artist: any) => artist.name)));
    }, []);
    return (<MultiSelect
        disabled={disabled} 
        label="Features"
        value={form.getInputProps('features').value}
        onChange={form.getInputProps('features').onChange}
        data={data}
        placeholder="Select items"
        searchable
        creatable
        getCreateLabel={(query) => `+ Create ${query}`}
        onCreate={(query) => setData((current) => [...current, query])}
      />)
}