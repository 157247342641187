import { Accordion, Text, Container, Title } from '@mantine/core';

const FAQ = () => {

    return (
        <section id="section-faq">
            <Container>
                <div>
                    <Text>
                        <Title order={1} style={{ marginTop: 10 }} align='center'>FAQ</Title>
                    </Text>
                </div>
                <Accordion>
                    <Accordion.Item label="How does it work?">
                        Sellers can submit groupbuys to our platform by providing a song, snippet and information regarding it. Staff verify the sale and approve it to the platform. Buyers can deposit to their Musiclocker wallet via multiple different cryptocurrencies. Buyers can then bid on their favorite groupbuys and once the goal is hit, the song is released.
                    </Accordion.Item>
                    <Accordion.Item label="Why use Musiclocker?">
                        The unreleased music community is plagued by scammers and other repercussions that follow it. Musiclocker eliminates scamming with implementation of the Musiclocker escrow system, and also will not be terminated repeatedly for DMCA. We offer a hassle-free experience.
                    </Accordion.Item>

                    <Accordion.Item label="Is Musiclocker secure?">
                        Yes! Musiclocker cares deeply about the security of it's users and their products. Musiclocker utilizes the strongest of password hashing algorithms and does not log user metadata beyond what is needed for functionality. We do not log IP addresses and offer sellers the option to host sales anonymously. Musiclocker also utilizies PPK  encryption for sellers files, ensuring that their files are always safe until they are released at the end of a buy. 
                    </Accordion.Item>
                </Accordion>
            </Container>
        </section>
    );

};

export default FAQ;