import FAQ from "Components/LandingPage/Faq";
import { HeroBullets } from "Components/LandingPage/HeroHeader";
import IndexActiveGroupbuys from "Components/LandingPage/TrendingGroupBuySection";

export default () => {
    return (<>
        <HeroBullets/>
        <IndexActiveGroupbuys/>
        <FAQ/>
    </>);
};