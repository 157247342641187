import { TrackEditSchema } from 'Models/Tracks';
import {client} from './axios';

export function getAllArtists() {
    return client.get('artist/all');
}

export function searchArtists(query: string) {
    return client.get(`artist/search/${query}`);
};

export function createTrack(creationData: TrackEditSchema) {
    return client.post('tracks/create', {
        ...creationData
    });
}

export function editTrack(id: String, editData: TrackEditSchema) {
    return client.post('tracks/edit/'+id, {...editData});
}

export function fetchTrack(id: string) {
    return client.get('tracks/'+id);
}

export function uploadTrack(id: string, track: File) {
    const formData = new FormData();
    formData.append("file", track);
    return client.post('tracks/upload/'+id, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}